import React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Main from 'layouts/Main';
import { Button } from '@mui/material';
import Container from 'components/Container';
import { Form } from 'views/SportClub/components';

const mock = [
  {
    name: 'Year 2023-24',
    title: 'Click here',
    
    href:'/sports-club-2023',
  },
  {
    name: 'Year 2022-23',
    title: 'Click here',
    
    href:'/sport-club',
  },

];

const SportsClub= () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
      <Box
        position={'relative'}
        minHeight={'calc(100vh - 247px)'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        height={1}
      >
         <Container maxWidth={0.88} paddingTop={'0 !important'}  >
          <Grid container spacing={6}>
            <Grid
              item
              container
              alignItems={'center'}
              justifyContent={'center'}
              xs={12}
              md={6}
            >
             <Form/>
            </Grid>
            <Grid
              item
              container
              alignItems={'center'}
              justifyContent={'center'}
              xs={12}
              md={6} 
            >
              <Box height={1} width={1} maxWidth={500}>
                  <Box
                    component={'img'}
                    src={
                      'https://i.imgur.com/5AhrjqU.jpg'
                    }
                    width={1}
                    height={1}
                    sx={{
                      filter:
                        theme.palette.mode === 'dark'
                          ? 'brightness(0.8)'
                          : 'none',
                    }}
                  />
                </Box>
            </Grid>
          </Grid>
{/* <Achievements/> */}
        </Container>
       
      </Box>

      <Container>
      <Box>
        <Box marginBottom={4}>
          <Typography
            sx={{
              textTransform: 'uppercase',
            }}
            gutterBottom
            color={'text.secondary'}
            align={'center'}
            fontWeight={700}
          >
            {/* ARCHIVES */}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {mock.map((item, i) => (
            <Grid item xs={12} md={4} key={i}>
              <Box
                width={0.9}
                height={0.9}
                component={Card}
                boxShadow={0}
                variant={'outlined'}
                bgcolor={'alternate.main'}
              >
                <CardContent sx={{ padding: 3 }}>
                  <ListItem component="div" disableGutters sx={{ padding: 0 }}>
                  
                    <Button 
                    href={item.href}>
                    <ListItemText
                      sx={{ margin: 0 }}
                      primary={item.name}
                      secondary={item.title}
                      primaryTypographyProps={{
                        variant: 'h6',
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{ variant: 'subtitle1' }}
                    />
                    </Button>
                  </ListItem>
                </CardContent>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>

    </Main>
  );
};

export default SportsClub;
