/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';



const Form = () => {

  return (
    <Box>
      <Box marginBottom={4}>

        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
           SPORTS CLUB
        </Typography>
        <br />
        {/* <br />  <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
          }}
        >
            NURTURE NATURE FOR A BETTER FUTURE!
        </Typography> */}
        <Typography
        component='p'
        align='justify'
        >
         
       Sports is mandatory. A Cricket field, Basketball court, Volleyball court, Badminton court, Table Tennis room, Indoor games room with Carrom and Chess and Taekwondo classes, are conducted for grooming the future players of the country. Inter-house matches are held in Khabbadi, Football, Throwball, Basketball and Volleyball in addition to athletic events.
  

        </Typography>
      </Box>
    </Box>
  );
};


export default Form;
