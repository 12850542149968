// SportClub
import React from 'react';
import SportsClub from '../views/Archive2023/components/SportsClub/SportsClub';


const SportClubPage = () => {
  return <SportsClub />;
};

export default SportClubPage;
